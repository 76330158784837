@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  font-family: "Poppins", sans-serif;
  background: linear-gradient(180deg, #c1b8e7 0%, #a79fda 100%);
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 1380px;
  min-height: 900px;
}

.color-base {
  color: #595284;
}

.color-base-lite {
  color: #7770a8;
}

.dashboard {
  background: #2b2549;
  width: 1280px;
  height: 800px;
  margin: 40px;
}

.sidebar-right {
  background-color: #272042;
}

.sidebar-left {
  background-color: #342e59;
}
.sidebar-left-active {
  background-color: #3b3363;
}

.mini-icon {
  color: #514a7b;
}

.input-search {
  background-color: transparent;
  border-bottom: 1px solid #383061;
}

.input-search::placeholder {
  color: #383061;
  opacity: 1;
}

.input-search-icon {
  transform: translate(0, -50%);
}

.tag-red {
  background: #f65164;
}

.tag-red-dot {
  background: #ff96a2;
}
.tag-yellow-dot {
  background: #d99a51;
}

.top-selection-active {
  color: #d99a51;
}

.player {
  border-top: 16px solid #2b2549;
  border-right: 16px solid #2b2549;
}

.avatar-ring {
  background: conic-gradient(
    from 0deg at 50% 50%,
    #2ea1cb -0.27deg,
    #6432c5 95.62deg,
    #c25d8d 169.05deg,
    #a9ceb2 330.58deg,
    #2ea1cb 359.73deg,
    #6432c5 455.63deg
  );
  clip-path: path(
    "M28 1.52794C28 0.684082 28.6847 -0.00434746 29.5273 0.0416656C35.4595 0.365612 41.1501 2.57002 45.763 6.35571C50.7705 10.4653 54.1982 16.184 55.462 22.5375C56.7258 28.8909 55.7475 35.4861 52.6938 41.1991C49.6401 46.9122 44.7 51.3896 38.7151 53.8686C32.7303 56.3476 26.071 56.6748 19.872 54.7943C13.673 52.9139 8.3178 48.9422 4.71885 43.556C1.11989 38.1697 -0.500123 31.7023 0.134828 25.2555C0.71974 19.3168 3.18486 13.7342 7.15049 9.31045C7.71377 8.68211 8.68474 8.68472 9.28143 9.28142V9.28142C9.87813 9.87812 9.87417 10.8426 9.31511 11.4747C5.8472 15.3957 3.6916 20.3201 3.17599 25.555C2.61034 31.2982 4.05355 37.0598 7.25972 41.8582C10.4659 46.6566 15.2366 50.1948 20.7591 51.87C26.2816 53.5453 32.214 53.2538 37.5457 51.0454C42.8774 48.8369 47.2783 44.8481 49.9987 39.7586C52.7192 34.6691 53.5907 28.7937 52.4648 23.1336C51.339 17.4736 48.2854 12.379 43.8244 8.71794C39.7581 5.38083 34.7518 3.42299 29.5271 3.10264C28.6848 3.051 28 2.3718 28 1.52794V1.52794Z"
  );
}
.fadeInUp {
  animation: animationFadeUp 1s;
  animation-timing-function: ease-out;
}

@keyframes animationFadeUp {
  from {
    opacity: 0;
    transform: translate3d(0, 10%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
.fadeInDown {
  animation: animationFadeDown 1s;
  animation-timing-function: ease-out;
  opacity: 0;
  transform: translate3d(0, -50%, 0);
  animation-fill-mode: forwards;
}

@keyframes animationFadeDown {
  from {
    opacity: 0;
    transform: translate3d(0, -50%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
.ping-outer {
  position: absolute;
  right: 15px;
  top: 15px;
  width: 10px;
  height: 10px;
  border-radius: 999px;
  background-color: #ea4d5f;
  z-index: 99;
  animation: ping-online 1s cubic-bezier(0, 0, 0.2, 1) infinite;
}
@keyframes ping-online {
  75%,
  to {
    transform: scale(3.5);
    opacity: 0;
  }
}
